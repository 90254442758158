import React, { useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import AbortController from "abort-controller";

import ProductsListingInfinite from "../../Components/Products/ProductsListingInfinite";
import { APP_NAME } from "../../config/constants";
import { smoothScrollToTop } from "../../helpers/commonHelper";
import { apiGetProductDetail, apiGetProducts } from "../../store/products/actions";
import { clearProductList } from "../../store/products/slice";

const Productlist = () => {
  let [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { currentCurrency } = useSelector(s => s.config);
  const { detail } = useSelector((s) => s.products.productDetail);
  const { isLoading, items, hasMore, message, skip, others } = useSelector((s) => s.products.products);
  const cancelToken = useRef(null);

  const limit = 32;


  const handleFetchRequest = async (init = false) => {
    if (!isLoading || init) {
      if (cancelToken?.current) {
        cancelToken.current.abort();
      }
      setTimeout(() => {
        cancelToken.current = new AbortController();
        dispatch(apiGetProducts({
          query: {
            limit: limit,
            skip: init ? 1 : skip + 1,
            category: searchParams.get("category"),
            search: searchParams.get("search")
          },
          signal: cancelToken.current.signal,
        })).then(({ payload }) => {
        })
      }, 500);
    }
  };

  useEffect(() => {
    if (searchParams) {
      smoothScrollToTop();
      dispatch(clearProductList("products"));
      handleFetchRequest(true);
    }
  }, [searchParams, currentCurrency?.code]);

  useEffect(() => {
    const topIds = searchParams.get("topIds");
    if (topIds) {
      dispatch(apiGetProductDetail({ id: topIds }));
    }
  }, [dispatch, searchParams]);

  return (
    <div className="wrapList">
      <Helmet>
        <title>{APP_NAME} | Shop</title>
      </Helmet>
      <Container fluid>
        <Row>
          {others?.category?.catImage ? (
            <>
              <Col lg={12} className="mb-4">
                <div className="w-100 position-relative">
                  <img src={others?.category?.catImage} alt="category" style={
                    {
                      width: "100%",
                      height: "200px",
                      objectFit: "cover"
                    }
                  } />
                  <h3 style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    "-webkit-text-stroke-color": "#929292",
                    "-webkit-text-stroke-width": "thin",
                    fontWeight: 900,
                    fontSize: "45px",
                    textWrap: "auto",
                  }}>{others?.category?.catName}</h3>
                </div>
              </Col>
            </>
          ) : null}
          {/* <Col lg={3}>
            <Sidefilleter />
          </Col> */}
          <Col lg={12}>
            <h5 className="all_product_head text-start mb-4">
              {searchParams.get("search") ? `Search for "${searchParams.get("search")}"` : (searchParams.get("name") || "All Product List")}
            </h5>
            <ProductsListingInfinite
              {...{
                items:
                  items?.length <= limit && detail
                    ? [
                      ...[
                        detail,
                        ...items?.filter((i) => i._id !== detail._id),
                      ],
                    ]
                    : items,
                isLoading,
                message,
                hasMore,
                fetchRecords: handleFetchRequest,
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Productlist;
