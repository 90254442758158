import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Container, Dropdown } from "react-bootstrap";
import ROUTES from "../../helpers/routesHelper";
import { logger } from "../../helpers/commonHelper";
import ItemCategory from "./Items/ItemCategory";
import ItemProduct from "./Items/ItemProduct";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencySymbol } from "../../helpers/currencyHelper";
import { setCurrency } from "../../store/config/slice";
import { defaultExchangeRate } from "../../config/constants";

const Homemenustrip = ({ scroll }) => {
  const dispatch = useDispatch();
  const { currencies, currentCurrency } = useSelector(s => s.config);
  const [top, setTop] = useState(0);
  const productRef = useRef();

  const handleScroll = () => {
    setTop(productRef.current.getBoundingClientRect().top + productRef.current.getBoundingClientRect().height);
  };

  useEffect(() => {
    handleScroll();
    document?.body?.addEventListener("scroll", handleScroll);
    return () => {
      document?.body?.removeEventListener("scroll", handleScroll);
    };
  }, [productRef]);

  // useEffect(() => {
  //   logger("TOP ", top);
  // }, [top]);

  return (
    <div className="home_strip px-0">
      <Container>
        <div className="d-flex align-items-center justify-content-between">

          <ul
            className="homeMenu_list m-0 p-0 d-flex align-items-center"
            ref={productRef}
            onMouseOver={() => handleScroll()}
          >
            <li>
              <NavLink to={ROUTES.HOME}>Home</NavLink>
            </li>

            <ItemCategory top={top} scroll={scroll} />
            <ItemProduct top={top} scroll={scroll} />

            <li>
              <Link to={ROUTES.BLOG}>Blog</Link>
            </li>

            <li>
              <Link to={ROUTES.ABOUT_US}>About</Link>
            </li>

            <li>
              <Link to={ROUTES.CONTACT_US}>Contact</Link>
            </li>

            {/* <li>
              <Link to={ROUTES.VENDOR_REGISTRATION}>Merchant Signup</Link>
            </li> */}
          </ul>
          <Dropdown>
            <Dropdown.Toggle variant="transparent" className="border-0 p-0" id="dropdown-basic"
              style={{ fontSize: 12 }}>
              <span className="icn me-1 flag">
                <img src={currentCurrency?.imageUrl || defaultExchangeRate?.imageUrl} onError={(e) => {
                  e.target.remove();
                }}
                  style={{ height: 15 }} alt="" className="img-fluid object-fit-contain" />

              </span> {currentCurrency?.name || defaultExchangeRate?.name}
            </Dropdown.Toggle>

            <Dropdown.Menu className="rightMenu" >
              {currencies?.map((currency, key) => (
                <Dropdown.Item className={`rounded-0 ${currency.code === getCurrencySymbol() ? "active" : ""}`} onClick={() => dispatch(setCurrency(currency))} key={key}>{currency.name}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* <select name="lang" className="form-control w-auto border-0 p-0" id="lang" onChange={(e) => {
            dispatch(setCurrency(e.target.value))
          }}>
            {currencies?.map((currency, key) => (
              <option value={key} key={key} selected={currency.code === getCurrencySymbol()}>{currency.name}</option>
            ))}
          </select> */}
        </div>
      </Container>

    </div>
  );
};

export default Homemenustrip;
