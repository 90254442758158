import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useLocation, useParams, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { ProductVariations } from "./ProductVariations";
import CartPropup from "../../Components/CartPopup";
import LoginPopup from "../../Components/LoginPopup";
import LoadingContent from "../../Components/Common/LoadingContent";
import AddToCart from "../../Components/Common/AddToCart";
import NoRecordFound from "../../Components/Common/NoRecordFound";

import { APP_NAME } from "../../config/constants";
import { addToCart, cartDetails, getItemPrice, getProductStock } from "../../helpers/cartHelper";
import { amountConversion, ENVIRONMENT, formatNumber, logger, parseText } from "../../helpers/commonHelper";
import { apiGetProductDetail } from "../../store/products/actions";
import { manageProductForCart } from "../../store/products/slice";

import placeholder from "../../assets/images/sousix.jpg";
import FeatureAttributes from "./FeatureAttributes";
import SlideImage from "./SlideImage";
import ProductPricing from "../../Components/Common/ProductPricing";
import TempErrorMessage from "../../Components/Common/TempErrorMessage";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
        <path fill="#233448" d="m14.475 12l-7.35-7.35q-.375-.375-.363-.888t.388-.887t.888-.375t.887.375l7.675 7.7q.3.3.45.675t.15.75t-.15.75t-.45.675l-7.7 7.7q-.375.375-.875.363T7.15 21.1t-.375-.888t.375-.887z" />
      </svg>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
        <path fill="#233448" d="m9.55 12l7.35 7.35q.375.375.363.875t-.388.875t-.875.375t-.875-.375l-7.7-7.675q-.3-.3-.45-.675t-.15-.75t.15-.75t.45-.675l7.7-7.7q.375-.375.888-.363t.887.388t.375.875t-.375.875z" />
      </svg>
    </div>
  );
}

const Singleview = () => {
  const dispatch = useDispatch();
  const { currentCurrency } = useSelector(s => s.config);
  const appConfig = useSelector(s => s.config.data);
  const { isLoading, detail, message, outOfStock } = useSelector((s) => s.products.productDetail);
  const { isLogin } = useSelector((s) => s.auth);
  const { id } = useParams();

  const sliderRef = useRef(null);
  const componentRef = useRef(null);
  const [quantityError, setQuantityError] = useState("");
  const [show, setShow] = useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [like, setLike] = useState(false);
  const [cartQuantity, setCartQuantity] = useState(1);

  const [search] = useSearchParams();

  const handleLoginClose = () => setLoginShow(false);
  const handleLoginShow = () => setLoginShow(true);

  const handleImageClick = (index) => {
    console.log(index);
    setCurrentImageIndex(index);
  };

  const liked = () => {
    setLike(!like);
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(currentImageIndex);
    }
  }, [currentImageIndex]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "#ddd",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div className="thumbnail_img">
        <img
          src={productImages[i] || placeholder}
          alt=""
          className="img-fluid"
        />
        <div className="elllispe_shape">
          <div className="ellispse_inner"></div>
        </div>
      </div>
    ),
  };

  const handlerAddToCart = () =>
    !isLogin ? handleLoginShow() :
      cartDetail?.directCart ? addToCart(detail, dispatch, cartQuantity) :
        cartDetail?.directVariationCart ? addToCart(detail, dispatch) :
          setShow(true);

  const getProductImages = () => {
    let images = detail?.images?.length ? detail?.images?.map(img => img) : [detail?.featured_image]

    if (detail?.attributes?.length) {
      detail?.attributes?.forEach((attr) => {
        attr?.terms?.forEach((term) => {
          if (term.image) {
            images.push(term.image);
          }
        });
      });
    }
    return images;
  }


  useEffect(() => {
    dispatch(apiGetProductDetail({ id }));
  }, [id, dispatch, currentCurrency?.code]);

  useEffect(() => {
    return () => {
      dispatch(manageProductForCart(null));
    };
  }, []);

  useEffect(() => {
    if (outOfStock) {
      componentRef.current = setTimeout(() => {
        window.location.href = atob(search.get("redirectUrl"));
      }, 3000);
    }

    return () => {
      clearTimeout(componentRef.current);
    };
  }, [outOfStock]);

  const cartDetail = cartDetails(detail);
  const productStock = getProductStock(detail);
  const productImages = getProductImages(detail);
  const setMaxStock = (value) => {
    if (detail?.manage_stock && value > detail?.stock_quantity) {
      setQuantityError(`The product has only ${detail?.stock_quantity} items(s) left.`)
    }
    else {
      setQuantityError(``)
    }
    return detail?.manage_stock ? Math.min(value, detail?.stock_quantity) : value;
  }
  return (
    <section className="single_view my-5" style={{ backgroundColor: "white" }}>
      <Helmet>
        <title>{APP_NAME} | Product details</title>
      </Helmet>
      <Container>
        {isLoading ? (
          <LoadingContent />
        ) : detail ? (
          <>
            <Row>
              <Helmet>
                <title>{APP_NAME} | {outOfStock ? "Out of stock" : `${detail?.name}`}</title>
              </Helmet>
              {outOfStock ? (
                <>
                  <NoRecordFound message={message} />
                </>
              ) : (
                <>
                  <Col lg={7} sm={12}>
                    <div className="product_preview text-start pe-4 lg-pe-0">
                      <h4>{detail?.name || ""}</h4>
                      <div className="d-flex gap-3">
                        <p>
                          {!detail?.average_rating
                            ? "No reviews yet"
                            : "Need to add rating star"}{" "}
                          {detail?.sold_count ? (
                            <>
                              <span className="dot"></span> {detail?.sold_count} sold
                            </>
                          ) : (
                            ""
                          )}{" "}
                        </p>
                        {ENVIRONMENT === "development" && detail?.offerId ? <Link className="btn btn-sm rounded rounded-5" to={`https://detail.1688.com/offer/${detail?.offerId}.html`} target="_blank" style={{
                          height: "25px",
                          padding: "3px 10px",
                          background: "var(--theme-yellow)",
                          fontSize: "12px",
                          fontWeight: 500,
                          border: "1px solid var(--theme-color)",
                        }}>1688.com</Link> : ""}
                      </div>
                    </div>

                    <div className="sinlge_product_slider position-relative">
                      <Slider ref={sliderRef} {...settings}>
                        {productImages?.map((link, imgIdx) => <SlideImage key={imgIdx} link={link} />)}
                      </Slider>
                    </div>
                  </Col>

                  <Col lg={5} sm={12}>
                    <div className="product_right_vcariant text-start py-4 px-3">

                      <ProductPricing
                        price_tiers={detail?.price_tiers}
                        defaultPrice={detail?.price}
                      />

                      {detail?.price_tiers?.length && detail?.price_tiers[0]?.startQuantity > 1 ? <p className="min-quantity-label ms-0">{"Min. order: " + formatNumber(detail?.price_tiers[0]?.startQuantity) + " pieces"}</p> : null}

                      <hr />

                      {detail?.variations?.length ? (
                        <ProductVariations
                          detail={detail}
                          show={show}
                          setShow={setShow}
                          handlerAddToCart={handlerAddToCart}
                          onClick={(img) => handleImageClick(productImages.indexOf(img))}
                        />
                      ) : (
                        ""
                      )}

                      {cartDetail?.directCart && productStock?.instock ? (
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-wrap">
                            <p className="me-3 mb-1"><strong>Qty: </strong>{formatNumber(cartQuantity)}</p>
                            <p className="me-3 mb-1"><strong>Unit Price: </strong>{currentCurrency?.symbol} {formatNumber(getItemPrice(detail, cartQuantity))}</p>
                            <p className="mb-1"><strong>Price: </strong>{currentCurrency?.symbol} {formatNumber(getItemPrice(detail, cartQuantity) * cartQuantity)}</p>
                          </div>
                          <TempErrorMessage message={quantityError} temp={false} />
                        </div>
                      ) : null}
                      <div className="button_three d-flex align-items-center justify-content-between gap-2 mt-3">
                        {!cartDetail?.directCart ? (!cartDetail?.directVariationCart ?
                          (
                            <Button
                              className="Startbutton"
                              onClick={handlerAddToCart}
                            >
                              Start Order
                            </Button>
                          ) :
                          null
                        ) : (
                          <div className="counter_div d-flex align-items-center gap-2 justify-content-end bg-transparent">
                            {cartDetail?.directCart && productStock?.instock ?
                              <>
                                <AddToCart
                                  value={cartQuantity}
                                  onChange={(value) => {
                                    setCartQuantity(setMaxStock(Math.max(1, parseInt(value))));
                                  }}
                                  onDecrement={() => {
                                    setCartQuantity((s) => setMaxStock(Math.max(1, s - 1)));
                                  }}
                                  onIncrement={() => {
                                    setCartQuantity((s) => setMaxStock(s + 1));
                                  }}
                                />
                              </> : <p className="out-of-stock">Out of stock</p>}
                          </div>
                        )}

                        {cartDetail?.directCart && productStock?.instock ?
                          <Button className="addcart" onClick={handlerAddToCart}>
                            Add to cart
                          </Button> : !cartDetail?.directCart ? <Button className="addcart" onClick={handlerAddToCart}>
                            Add to cart
                          </Button> : null}
                        <Button className="chatbtn">{chaticon}</Button>
                      </div>
                    </div>
                  </Col>
                </>
              )}
            </Row>

            <FeatureAttributes details={detail?.featureAttribute} />

            {detail?.description ?
              <div className="product_description mx-auto" style={{ maxWidth: 900 }}>
                <Row className="text-start mt-5 ">
                  <Col lg="12">
                    <h3 className="pb-3">Product description</h3>
                  </Col>
                  <Col lg="12" className="uza-product-description" dangerouslySetInnerHTML={{ __html: parseText(detail?.description) }}>

                  </Col>
                </Row>
              </div>
              : null}
          </>
        ) : (
          <NoRecordFound />
        )}

        <CartPropup show={show} setShow={setShow} detail={detail} handleLoginShow={handleLoginShow} />
        <LoginPopup show={loginShow} handleClose={handleLoginClose} />
      </Container>
    </section >
  );
};

export default Singleview;

const chaticon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 256 256">
    <path fill="#000" d="M216 52H40a12 12 0 0 0-12 12v160a11.89 11.89 0 0 0 6.93 10.88A12.2 12.2 0 0 0 40 236a11.9 11.9 0 0 0 7.69-2.83L81.49 204H216a12 12 0 0 0 12-12V64a12 12 0 0 0-12-12m4 140a4 4 0 0 1-4 4H80a4 4 0 0 0-2.62 1l-34.82 30.06A4 4 0 0 1 36 224V64a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4Zm-56-80a4 4 0 0 1-4 4H96a4 4 0 0 1 0-8h64a4 4 0 0 1 4 4m0 32a4 4 0 0 1-4 4H96a4 4 0 0 1 0-8h64a4 4 0 0 1 4 4" />
  </svg>
);
