import { toast } from "react-toastify";
import { manageProductForCart } from "../store/products/slice";
import { isEqualArray, scrollToId, logger, formatNumber } from "./commonHelper";
import { apiAddToCart, apiUpdateCart } from "../store/cart/actions";
// import { apiDeleteCart } from "../store/cart/actions";
import { updateCartList } from "../store/cart/slice";
import { defaultExchangeRate } from "../config/constants";

export const getItemPrice = (detail, productQuantity = 1) => {
  let cartQuantity = detail?.variations?.length ? detail?.variations?.reduce((sum, variation) => (variation?.quantity || 0) + sum, 0) : productQuantity;
  const length = detail.price_tiers?.length;
  if (length) {
    for (let index = length - 1; index >= 0; index--) {
      if (cartQuantity >= detail.price_tiers[index].startQuantity) {
        return detail.price_tiers[index].price;
      }
    }

    return detail.price_tiers[0].price;
  }
  return detail?.price;
};

export const onChangeVariation = ({
  detail,
  attributeIndex,
  termIndex,
  dispatch,
}) => {
  let newAttributes = detail.attributes.map((attr, attrIndex) => {
    if (attrIndex === attributeIndex) {
      // Create a new array of terms
      let newTerms = attr.terms.map((trm, id) => {
        if (id === termIndex) {
          return { ...trm, active: true };
        }
        return { ...trm, active: false };
      });
      return { ...attr, terms: newTerms }; // Return new attribute object with updated terms
    }
    return attr; // Return unchanged attribute
  });

  // Create a new product object with updated attributes
  const updatedProduct = { ...detail, attributes: newAttributes };
  dispatch(manageProductForCart(updatedProduct));
};

export const onChangeQuantity = ({
  termId,
  detail,
  dispatch,
  increase = false,
  setQuantity = null,
  setError = () => { }
}) => {
  let cartQuantity = 0;
  let newVariations = [];

  // Cart by variations
  if (detail?.variations?.length) {
    // Identify active attributes
    const activeAttributes = [];

    detail.attributes.forEach((attributes, ind) => {
      if (ind !== detail?.attributes?.length - 1) {
        for (const term of attributes.terms) {
          if (term.active) {
            activeAttributes.push(term._id);
          }
        }
      } else {
        activeAttributes.push(termId);
      }
    });

    // Find the index of the matching variation
    let variationIndex = -1;
    detail?.variations?.forEach((variation, id) => {
      const attributes = variation?.attributes?.map((attr) => attr._id);
      if (isEqualArray(attributes, activeAttributes)) {
        variationIndex = id;
      }
    });

    if (variationIndex === -1) {
      console.error("No matching variation found");
      return;
    }

    // Create a new copy of the variation object and update quantity
    newVariations = detail.variations.map((variation, index) => {
      logger("CHECK QUANTITY:::", variation);
      if (index === variationIndex) {
        let newQuantity = 0;
        if (setQuantity !== null) {
          newQuantity = Math.max(setQuantity, 0);
        } else {
          newQuantity = variation.quantity
            ? increase
              ? variation.quantity + 1
              : Math.max(variation.quantity - 1, 0)
            : increase
              ? 1
              : 0;
        }

        if (variation?.manage_stock && newQuantity > variation.stock_quantity) {
          setError(`The product variation has only ${variation.stock_quantity} items(s) left.`);
        }
        else {
          setError("");
        }

        newQuantity = (variation?.manage_stock) ? Math.min(variation.stock_quantity, newQuantity) : newQuantity

        cartQuantity += newQuantity;

        return {
          ...variation,
          quantity: newQuantity,
        };
      }

      if (variation.quantity) {
        cartQuantity += variation.quantity;
      }
      return variation;
    });
  }

  // Dispatch the updated product details to the cart
  dispatch(
    manageProductForCart({
      ...detail,
      cartPrice: cartQuantity * getItemPrice(detail),
      variations: newVariations,
    })
  );
};

export const cartDetails = (detail) => {
  let minQuantity = 1;
  let maxQuantity = Infinity;
  let quantity = 0;
  let variations = 0;

  const tiersLength = detail?.price_tiers?.length;
  if (tiersLength) {
    // minQuantity = detail.price_tiers[0].minQuantity;
    // maxQuantity = detail.price_tiers[tiersLength - 1].infinit
    //   ? Infinity
    //   : detail.price_tiers[tiersLength - 1].price;
  }

  if (detail?.variations?.length) {
    detail?.variations?.forEach((variation, index) => {
      if (variation.quantity) {
        quantity += variation.quantity;
        variations++;
      }
    });
  }

  return {
    quantity,
    variations,
    isReadyToCart: quantity >= minQuantity,
    directCart: detail?.variations?.length ? false : true,
    directVariationCart: detail?.attributes?.length === 1 ? true : false,
    minQuantity: maxQuantity,
  };
};

const getAttributeId = (attributes, id) => {
  for (const attr of attributes) {
    for (const term of attr.terms) {
      if (term._id === id) {
        return attr._id;
      }
    }
  }

  return null;
};

export const addToCart = (detail, dispatch, cartQuantity = 1) => {
  let data = {
    product: detail._id,
    items: [],
  };

  if (detail?.variations?.length) {
    detail?.variations?.forEach((variation) => {
      if (variation.quantity) {
        let item = {
          product: detail._id,
          quantity: variation.quantity,
          variation_id: variation._id,
          attributes: [],
        };

        variation.attributes.forEach((attribute) => {
          item.attributes.push({
            attrId: getAttributeId(detail?.attributes, attribute._id),
            attrTermId: attribute._id,
          });
        });
        data.items.push(item);
      }
    });
  } else {
    data.items.push({
      product: detail._id,
      quantity: cartQuantity,
    });
  }

  if (data?.items?.length) {
    dispatch(
      apiAddToCart({
        data,
        callback: () => {
          toast.success("Cart updated successfully!");
        },
      })
    );
  } else {
    toast.warn("Please add items to add cart!");
  }
};

let delToken, updateToken;
// Cart listing page
export const manageCartQuantity = ({
  cartList,
  cartListIndex,
  cart,
  cartIndex,
  dispatch,
  increase = true,
  setValue = null,
  callback = () => { },
}) => {
  const items = cart.items.map((item, i) => {
    if (i === cartIndex) {
      const quantity = Math.max(
        setValue !== null
          ? setValue
          : increase
            ? item.quantity + 1
            : item.quantity - 1,
        0
      );
      return {
        ...item,
        quantity: isNaN(quantity) ? 0 : quantity,
      };
    } else {
      return item;
    }
  });

  const newCart = [...cartList];
  newCart[cartListIndex] = { ...newCart[cartListIndex], items, isLoading: true };

  dispatch(updateCartList(newCart));

  if (items[cartIndex].quantity === 0) {
    clearTimeout(delToken);
    delToken = setTimeout(() => {
      dispatch(
        apiUpdateCart({
          id: cart._id,
          data: {
            operateType: "MANUAL_DELETED",
            items: [
              {
                _id: items[cartIndex]._id,
              },
            ],
          },
          callback
        })
      );
    }, 1000);
  } else {
    // Update cart quantity
    clearTimeout(updateToken);
    updateToken = setTimeout(() => {
      dispatch(
        apiUpdateCart({
          id: cart._id,
          data: {
            operateType: "UPDATE",
            items: items.map((item) => ({
              _id: item._id,
              quantity: item.quantity,
              variation_id: item.variation_id,
              attributes: item.attributes?.map((attr) => ({
                attrId: attr.attrId,
                attrTermId: attr.attrTermId,
              })),
            })),
          },
          callback
        })
      );
    }, 1000);
  }
};

export const getCouponDiscount = ({ orderDetails, cart, cartItem, currencySymbol = defaultExchangeRate.symbol }) => {
  let discountText = "";
  if (orderDetails?.line_items?.length) {
    orderDetails?.line_items.forEach((item) => {
      item.items.forEach((value) => {
        if (value._id === cartItem._id && value.discountTotal) {
          discountText = `Coupon discount ${currencySymbol} ${formatNumber(value.discountTotal)}`;
        }
      });
    });
  }
  return discountText;
};

export const isReadyToPlaceOrder = (orderDetails) => {
  logger("orderDetails", orderDetails);
  if (orderDetails) {
    for (const item of orderDetails?.line_items || []) {
      let quantity = 0;
      for (const itm of item.items) {
        if (!itm?.stock?.instock) {
          return false;
        }
        else if (itm?.stock?.instock && itm?.stock?.quantity && itm?.stock?.quantity < itm.quantity) {
          return false;
        }
        quantity += itm.quantity;
      }

      if (quantity < item.items[0].minQuantity) {
        return false;
      }
    }

    return true;
  }
  else {
    return false;
  }

  return true;
};

let isScrolledToError = false;
export const getCheckoutErrorMessage = ({ orderDetails, cart, index }) => {
  if (index === 0) isScrolledToError = false;

  if (orderDetails) {
    for (const item of orderDetails?.line_items || []) {
      if (item.cart_id === cart._id) {
        let quantity = 0;
        for (const itm of item.items) {
          if (!itm?.stock?.instock || (itm?.stock?.instock && itm?.stock?.quantity && itm?.stock?.quantity === 0)) {
            return "The product " + (itm?.attributes?.length ? "\"" + (itm.attributes.map((a) => a.attrValue)).join(" / ") + "\" " : "") + "is out of stock.";
          }
          else if (itm?.stock?.instock && itm?.stock?.quantity && itm?.stock?.quantity < itm.quantity) {
            logger("ITEM", itm);
            return "The product " + (itm?.attributes?.length ? "\"" + (itm.attributes.map((a) => a.attrValue)).join(" / ") + "\" " : "") + "has only " + itm?.stock?.quantity + " items(s) left.";
          }
          quantity += itm.quantity;
        }

        if (quantity < item.items[0].minQuantity) {
          if (!isScrolledToError) {
            scrollToId(cart._id);
            isScrolledToError = true;
          }
          return "The total quantity of this product must be greater than the " + item.items[0].minQuantity;
        }
      }
    }
  }

  return "";
};

export const getMinQuantity = (cart) => {
  return cart.product?.price_tiers[0]?.startQuantity;
}

export const getActiveAttributes = (attributes, termId) => {
  return attributes?.map((attribute, ind) => {
    if (ind !== attributes.length - 1)
      for (const term of attribute.terms) {
        if (term.active) return term._id;
      }
    else return termId;
  });
}

export const getVariationStock = ({ attributes, variations, termId }) => {
  const activeAttributes = getActiveAttributes(attributes, termId);

  for (const variation of variations) {
    const attrs = variation?.attributes?.map((attr) => attr._id);
    if (isEqualArray(attrs, activeAttributes)) {
      if (!variation.manage_stock && variation.stock_status === "outofstock") {
        return { instock: false };
      }
      else if (variation.manage_stock) {
        return { instock: variation.stock_quantity > 0, quantity: variation.stock_quantity }
      }
      else {
        return { instock: true };
      }
    }
  }

  return { instock: true };
}

export const getProductStock = (detail) => {
  if (detail) {
    if (!detail.manage_stock && detail.stock_status === "outofstock") {
      return { instock: false };
    }
    else if (detail.manage_stock) {
      return { instock: detail.stock_quantity > 0, quantity: detail.stock_quantity }
    }
    else {
      return { instock: true };
    }
  }
  return { instock: false };
}

const updateStock = (data, stock = { instock: true, quantity: Infinity }) => {
  if (!data.manage_stock && data.stock_status === "outofstock") {
    stock.instock = false;
    stock.quantity = 0;
  }
  else if (data.manage_stock) {
    stock.instock = data.stock_quantity > 0;
    stock.quantity = data.stock_quantity;
  }

  return stock;
}

export const getPendingQuantity = (product) => {
  let pendingQty = 0;
  if (product?.variations?.length) {
    product.variations.forEach((variation) => {
      const { quantity } = updateStock(variation);
      pendingQty += quantity;
    });
  }
  else {
    const { quantity } = updateStock(product);
    pendingQty += quantity;
  }
  return pendingQty;
}