// import ReactPaginate from 'react-paginate';

import { useEffect } from "react";
import { useState } from "react";
import { logger } from "../../helpers/commonHelper";

import styles from "./common.module.scss"

// export default function Pagination({ key = 0, totalPages, handlePageClick, initialPage = 0 }) {
//     return (
//         <>
//             <ReactPaginate
//                 key={key}
//                 breakLabel="..."
//                 nextLabel={'>'}
//                 onPageChange={handlePageClick}
//                 pageCount={totalPages}
//                 previousLabel="<"
//                 renderOnZeroPageCount={null}
//                 className='pagination-wrapper'
//                 initialPage={initialPage}
//             // pageRangeDisplayed={2}
//             // marginPagesDisplayed={2}
//             />
//         </>
//     );
// }



export default function Pagination({ totalPages, handlePageClick, initialPage = 0 }) {
    const [page, setPage] = useState(initialPage + 1);

    useEffect(() => {
        setPage(initialPage + 1);
    }, [initialPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        logger("-> page:::", { event: { selected: newPage - 1 } })
        handlePageClick({ selected: newPage - 1 });
    };

    const generatePageNumbers = () => {
        const pages = [];
        const maxPagesToShow = 10;
        const startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
        const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li
                    key={i}
                    className={`uza-page ${page === i ? styles.active : ''}`}
                    onClick={() => handlePageChange(i)}
                >
                    {i}
                </li>
            );
        }

        return pages;
    };

    return (
        <>
            <div className={`${styles.paginationWrpper} d-flex align-items-center justify-content-center rounded`}>
                <ul className={`${styles.uzaPagination} uza-pagination d-flex align-items-center list-unstyled mb-0`}>
                    {page > 1 && (
                        <li
                            className="uza-prev"
                            onClick={() => handlePageChange(page - 1)}
                        >
                            {"<"}
                        </li>
                    )}
                    {generatePageNumbers()}
                    {page < totalPages && (
                        <li
                            className="uza-next"
                            onClick={() => handlePageChange(page + 1)}
                        >
                            {">"}
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}


