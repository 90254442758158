import { createSlice } from '@reduxjs/toolkit'
import { apiGetBestSalerProducts, apiGetGuaranteedProducts, apiGetHomeBestSalerProducts, apiGetHomeGuaranteedProducts, apiGetHomeNewArrivalProducts, apiGetHomeProducts, apiGetHomeSavingSpotlightProducts, apiGetHomeTopRankingProducts, apiGetNewArrivalProducts, apiGetProductDetail, apiGetProducts, apiGetSavingSpotlightProducts, apiGetTopRankingProducts } from './actions'
import { paginateFulfilled, paginateInfiniteFulfilled, paginatePending, paginateRejected, paginationInfiniteInitialState, paginationInitialState } from '../../helpers/reduxHelper';

const initialState = {
    products: paginationInfiniteInitialState,
    topRankingProducts: paginationInfiniteInitialState,
    newArrivalProducts: paginationInfiniteInitialState,
    savingSpotlightProducts: paginationInfiniteInitialState,
    guaranteedProducts: paginationInitialState,
    bestSalerProducts: paginationInitialState,

    homeProducts: paginationInitialState,
    homeTopRankingProducts: paginationInitialState,
    homeNewArrivalProducts: paginationInitialState,
    homeSavingSpotlightProducts: paginationInitialState,
    homeGuaranteedProducts: paginationInitialState,
    homeBestSalerProducts: paginationInitialState,

    productDetail: {
        isLoading: false,
        message: "",
        outOfStock: false,
        detail: null,
    },
}

export const slice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        manageProductForCart: (state, action) => {
            state.productDetail.detail = action.payload;
        },
        clearProductList: (state, action) => {
            state[action.payload] = { ...paginationInfiniteInitialState, isLoading: true };
        }
    },
    extraReducers: (builder) => {
        // Get products
        builder
            .addCase(apiGetProducts.fulfilled, paginateInfiniteFulfilled('products'))
            .addCase(apiGetProducts.pending, paginatePending('products'))
            .addCase(apiGetProducts.rejected, paginateRejected('products'));


        // Top ranking products
        builder
            .addCase(apiGetTopRankingProducts.fulfilled, paginateInfiniteFulfilled('topRankingProducts'))
            .addCase(apiGetTopRankingProducts.pending, paginatePending('topRankingProducts'))
            .addCase(apiGetTopRankingProducts.rejected, paginateRejected('topRankingProducts'));


        // Get new arrival products
        builder
            .addCase(apiGetNewArrivalProducts.fulfilled, paginateInfiniteFulfilled('newArrivalProducts'))
            .addCase(apiGetNewArrivalProducts.pending, paginatePending('newArrivalProducts'))
            .addCase(apiGetNewArrivalProducts.rejected, paginateRejected('newArrivalProducts'));


        // Get saving spotlight products
        builder
            .addCase(apiGetSavingSpotlightProducts.fulfilled, paginateInfiniteFulfilled('savingSpotlightProducts'))
            .addCase(apiGetSavingSpotlightProducts.pending, paginatePending('savingSpotlightProducts'))
            .addCase(apiGetSavingSpotlightProducts.rejected, paginateRejected('savingSpotlightProducts'));


        // Get guaranteed products
        builder
            .addCase(apiGetGuaranteedProducts.fulfilled, paginateFulfilled('guaranteedProducts'))
            .addCase(apiGetGuaranteedProducts.pending, paginatePending('guaranteedProducts'))
            .addCase(apiGetGuaranteedProducts.rejected, paginateRejected('guaranteedProducts'));


        // Get best saler product
        builder
            .addCase(apiGetBestSalerProducts.fulfilled, paginateFulfilled('bestSalerProducts'))
            .addCase(apiGetBestSalerProducts.pending, paginatePending('bestSalerProducts'))
            .addCase(apiGetBestSalerProducts.rejected, paginateRejected('bestSalerProducts'));


        // Get products
        builder
            .addCase(apiGetHomeProducts.fulfilled, paginateFulfilled('homeProducts'))
            .addCase(apiGetHomeProducts.pending, paginatePending('homeProducts'))
            .addCase(apiGetHomeProducts.rejected, paginateRejected('homeProducts'));


        // Top ranking products
        builder
            .addCase(apiGetHomeTopRankingProducts.fulfilled, paginateFulfilled('homeTopRankingProducts'))
            .addCase(apiGetHomeTopRankingProducts.pending, paginatePending('homeTopRankingProducts'))
            .addCase(apiGetHomeTopRankingProducts.rejected, paginateRejected('homeTopRankingProducts'));


        // Get new arrival products
        builder
            .addCase(apiGetHomeNewArrivalProducts.fulfilled, paginateFulfilled('homeNewArrivalProducts'))
            .addCase(apiGetHomeNewArrivalProducts.pending, paginatePending('homeNewArrivalProducts'))
            .addCase(apiGetHomeNewArrivalProducts.rejected, paginateRejected('homeNewArrivalProducts'));


        // Get saving spotlight products
        builder
            .addCase(apiGetHomeSavingSpotlightProducts.fulfilled, paginateFulfilled('homeSavingSpotlightProducts'))
            .addCase(apiGetHomeSavingSpotlightProducts.pending, paginatePending('homeSavingSpotlightProducts'))
            .addCase(apiGetHomeSavingSpotlightProducts.rejected, paginateRejected('homeSavingSpotlightProducts'));


        // Get guaranteed products
        builder
            .addCase(apiGetHomeGuaranteedProducts.fulfilled, paginateFulfilled('homeGuaranteedProducts'))
            .addCase(apiGetHomeGuaranteedProducts.pending, paginatePending('homeGuaranteedProducts'))
            .addCase(apiGetHomeGuaranteedProducts.rejected, paginateRejected('homeGuaranteedProducts'));


        // Get best saler product
        builder
            .addCase(apiGetHomeBestSalerProducts.fulfilled, paginateFulfilled('homeBestSalerProducts'))
            .addCase(apiGetHomeBestSalerProducts.pending, paginatePending('homeBestSalerProducts'))
            .addCase(apiGetHomeBestSalerProducts.rejected, paginateRejected('homeBestSalerProducts'));


        // Get product detail
        builder
            .addCase(apiGetProductDetail.fulfilled, (state, action) => {
                const { data, outOfStock, message } = action.payload;
                state.productDetail.message = message;
                state.productDetail.outOfStock = outOfStock;
                if (data?.attributes?.length) {
                    data.attributes = data?.attributes?.map((attribute, index) => {
                        attribute.terms = attribute?.terms?.map((term, idx) => {
                            term.active = (idx === 0 && index !== data?.attributes?.length - 1)
                            return term;
                        });
                        return { ...attribute };
                    })
                }
                state.productDetail.detail = data;
                state.productDetail.isLoading = false;
            })
            .addCase(apiGetProductDetail.pending, (state, action) => {
                state.productDetail.isLoading = true;
            })
            .addCase(apiGetProductDetail.rejected, (state, action) => {
                state.productDetail.isLoading = false;
            });
    },
})

export const { manageProductForCart, clearProductList } = slice.actions

export default slice.reducer