const ROUTES = {
  HOME: "/",
  LOGIN: "/auth/login",
  SIGNUP: "/auth/signup",
  FORGOT: "/auth/forgot-password",
  VENDOR_REGISTRATION: "/auth/merchant-registration",
  VENDOR_REGISTRATION_SUCCESS: "/auth/merchant-form-submitted",
  CATEGORIES: "/categories",
  PRODUCT_LISTING: "/products",
  TOP_RANKING_PRODUCT_LISTING: "/topRanking",
  NEW_ARRIVALS_PRODUCT_LISTING: "/newArrivals",
  SAVING_SPOTLIGHT_PRODUCT_LISTING: "/savingSpotlight",
  BEST_DEAL_PRODUCT_LISTING: "/dealsOnBestSeller",
  PRODUCT_DETAIL: "/product-view",
  CART: "/cart",
  CONGRATULATION: "/congratulation",
  BLOG: "/blog",
  CONTACT_US: "/contact-us",
  ABOUT_US: "/about-us",
  T_AND_C: "/term-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  PROFILE: "/profile",
  CHANGE_PASSWORD: "/change-password",
  MY_ORDERS: "/my-orders",
  ORDER_DETAIL: "/order-detail",
  ORDER_ADDRESS: "/address",
  CREATE_ADDRESS: "/address/save",
  CHECKOUT: "/checkout",
  UPLOAD_SLIP: "/upload-slip",
  PAGE_NOT_FOUND: "*",
};

export default ROUTES;
