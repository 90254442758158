import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ROUTES from '../helpers/routesHelper';

const PublicRoutesTheme = ({ isLogin }) => {

    const navigate = useNavigate();
    useEffect(() => {
        if (isLogin) {
            navigate(ROUTES.HOME);
        }
    }, [isLogin]);

    return <Outlet />;
};

export default PublicRoutesTheme;
