import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, FormGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import MobileNumberField, { MobileError } from "../../Components/Common/MobileNumberField";

import ButtonLoader from "../../Components/Common/ButtonLoader";
import { Col, Row } from "react-bootstrap";
import apiClient from "../../helpers/apiHelper";
import { AUTH } from "../../helpers/urlHelper";
import ROUTES from "../../helpers/routesHelper";
import FileDropzone from "./FileDropzone";
// import FileDropzone from "./FileDropzone";

const MerchantSignup = () => {
  const navigate = useNavigate();


  const initialValues = {
    // Personal and Business Information (as before)
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    address: "",
    city: "",
    region: "",
    postalCode: "",
    country: "",
    businessName: "",
    businessAddress: "",
    businessCity: "",
    businessRegion: "",
    businessPostalCode: "",
    businessCountry: "",
    countryCode: "",
    businessMobileNumber: "",
    businessEmail: "",
    businessCountryCode: "",
    documentsList: [],
    agreementAccepted: false,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    mobileNumber: Yup.string()
      .matches(/^\d+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits long")
      .required("Mobile number is required"),
    countryCode: Yup.string()
      .matches(/^\+\d+$/, "Country code must start with a '+' and contain only digits")
      .required("Country code is required"),
    businessName: Yup.string().required("Business name is required"),
    businessMobileNumber: Yup.string()
      .matches(/^\d+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits long")
      .required("Business mobile number is required"),
    businessCountryCode: Yup.string()
      .matches(/^\+\d+$/, "Country code must start with a '+' and contain only digits")
      .required("Country code is required"),
    businessEmail: Yup.string()
      .email("Invalid email format")
      .required("Business email is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    postalCode: Yup.string().required("Postal code is required"),
    country: Yup.string().required("Country is required"),
    businessAddress: Yup.string().required("Business address is required"),
    businessCity: Yup.string().required("City is required"),
    businessPostalCode: Yup.string().required("Postal code is required"),
    businessCountry: Yup.string().required("Country is required"),
    documentsList: Yup.array()
      .of(
        Yup.string().required("Please upload at least one document")
      )
      .min(1, "Please upload at least one document"),
    agreementAccepted: Yup.boolean()
      .oneOf([true], "You must accept the agreement.")
      .required("Agreement must be accepted."),
  });

  const onSubmit = (data, form) => {
    apiClient.post(AUTH.VENDOR_SIGNUP, data).then((res) => {
      if (res.status === "success") {
        toast.success("Merchant registered successfully");
        navigate(ROUTES.VENDOR_REGISTRATION_SUCCESS, {
          state: {
            data
          }
        });
      } else {
        toast.error(res?.message || "Something went wrong, please try again later.");
      }
    });
  };

  return (
    <section className="merchent_signup p-5">
      <Helmet>
        <title>Merchant Register</title>
      </Helmet>

      <Row>
        <Col>
          <div className="form-wrapper">
            <h4 className="mb-4">MERCHANT SIGNUP</h4>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ setFieldValue, isSubmitting, values }) => {
                return (
                  <Form>

                    <Row>
                      <Col md={12}>
                        <h6>Personal Information</h6>
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="firstName" className="required">First Name</label>
                              <Field
                                className="form-control"
                                name="firstName"
                                id="firstName"
                                placeholder="First Name *"
                              />
                              <ErrorMessage
                                name="firstName"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="lastName">Last Name</label>
                              <Field
                                className="form-control"
                                name="lastName"
                                id="lastName"
                                placeholder="Last Name"
                              />
                              <ErrorMessage
                                name="lastName"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="mobileNumber" className="required">Mobile Number</label>
                              <MobileNumberField
                                className="form-control"
                                callback={(code, number) => {
                                  setFieldValue("countryCode", code);
                                  setFieldValue("mobileNumber", number);
                                }}
                              />

                              <MobileError name="mobileNumber" />
                            </FormGroup>
                          </Col>


                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="email" className="required">Email</label>
                              <Field
                                className="form-control"
                                name="email"
                                id="email"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="email"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup>
                              <label htmlFor="address" className="required">Address</label>
                              <Field
                                className="form-control"
                                name="address"
                                id="address"
                                placeholder="Address *"
                              />
                              <ErrorMessage
                                name="address"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="city" className="required">City</label>
                              <Field
                                className="form-control"
                                name="city"
                                id="city"
                                placeholder="City *"
                              />
                              <ErrorMessage
                                name="city"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="region">Region</label>
                              <Field
                                className="form-control"
                                name="region"
                                id="region"
                                placeholder="Region"
                              />
                              <ErrorMessage
                                name="region"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="postalCode" className="required">Postal Code</label>
                              <Field
                                className="form-control"
                                name="postalCode"
                                id="postalCode"
                                placeholder="Postal Code *"
                              />
                              <ErrorMessage
                                name="postalCode"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="country" className="required">Country</label>
                              <Field
                                className="form-control"
                                name="country"
                                id="country"
                                placeholder="Country *"
                              />
                              <ErrorMessage
                                name="country"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={12} className="mt-4">
                        <h6>Business Information</h6>
                        <Row>
                          <Col md={12}>
                            <FormGroup>

                              <label htmlFor="businessName" className="required">Business name</label>
                              <Field
                                className="form-control"
                                name="businessName"
                                id="businessName"
                                placeholder="Business name *"
                              />
                              <ErrorMessage
                                name="businessName"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="businessMobileNumber" className="required">Mobile Number</label>
                              <MobileNumberField
                                className="form-control"
                                callback={(code, number) => {
                                  setFieldValue("businessCountryCode", code);
                                  setFieldValue("businessMobileNumber", number);
                                }}
                              />

                              <MobileError name="businessMobileNumber" />
                            </FormGroup>
                          </Col>


                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="businessEmail" className="required">Email</label>
                              <Field
                                className="form-control"
                                name="businessEmail"
                                id="businessEmail"
                                placeholder="Email"
                              />
                              <ErrorMessage
                                name="businessEmail"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup>
                              <label htmlFor="businessAddress" className="required">Business Address</label>
                              <Field
                                className="form-control"
                                name="businessAddress"
                                id="businessAddress"
                                placeholder="Business Address *"
                              />
                              <ErrorMessage
                                name="businessAddress"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="businessCity" className="required">City</label>
                              <Field
                                className="form-control"
                                name="businessCity"
                                id="businessCity"
                                placeholder="City *"
                              />
                              <ErrorMessage
                                name="businessCity"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="businessRegion">Region</label>
                              <Field
                                className="form-control"
                                name="businessRegion"
                                id="businessRegion"
                                placeholder="Region"
                              />
                              <ErrorMessage
                                name="businessRegion"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="businessPostalCode" className="required">Postal Code</label>
                              <Field
                                className="form-control"
                                name="businessPostalCode"
                                id="businessPostalCode"
                                placeholder="Postal Code *"
                              />
                              <ErrorMessage
                                name="businessPostalCode"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup>
                              <label htmlFor="businessCountry" className="required">Country</label>
                              <Field
                                className="form-control"
                                name="businessCountry"
                                id="businessCountry"
                                placeholder="Country *"
                              />
                              <ErrorMessage
                                name="businessCountry"
                                component="p"
                                className="text-danger"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <div>
                            <label>Upload Documents</label>
                            <FileDropzone onDrop={(acceptedFiles) => {
                              setFieldValue("documentsList", [...values.documentsList, acceptedFiles])
                            }} files={values.documentsList} />
                          </div>
                          <ErrorMessage
                            name="documentsList"
                            component="p"
                            className="text-danger"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <div className="default_house d-flex align-items-center">
                          <span className="me-2">
                            <Field
                              name="agreementAccepted"
                              type="checkbox"
                              id="agreementAccepted"
                            />
                          </span>
                          <label htmlFor="agreementAccepted" className="required">I agree to <Link to={ROUTES.T_AND_C}>Term of Service</Link></label>
                        </div>
                        <ErrorMessage
                          name="agreementAccepted"
                          component="p"
                          className="text-danger"
                        />
                      </Col>
                    </Row>


                    <div className="mt-3">
                      <Button
                        className="auth_btn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <ButtonLoader /> : "Submit"}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default MerchantSignup;
