import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import LoadingContent from "../../Components/Common/LoadingContent";

import ROUTES from "../../helpers/routesHelper";
import { amountConversion, fromNow } from "../../helpers/commonHelper";

import placeholder from "../../assets/images/gurfive.jpg";
import { getPendingQuantity } from "../../helpers/cartHelper";
import CommingSoon from "../Common/CommingSoon";
import { useSelector } from "react-redux";

const ProductsListingInfinite = ({
    items,
    isLoading,
    message = "",
    hasMore,
    fetchRecords,
}) => {
    const { currentCurrency } = useSelector(s => s.config);
    const appConfig = useSelector(s => s.config.data);

    const getMinMaxPrices = (item) => {
        const prices = [];

        // Add main price
        if (item.price) {
            prices.push(item.price);
        }

        // Add price tiers
        if (item.price_tiers && Array.isArray(item.price_tiers)) {
            item.price_tiers.forEach((tier) => {
                if (tier.price) {
                    prices.push(tier.price);
                }
            });
        }

        // Calculate min and max
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        return { minPrice, maxPrice };
    };
    return (
        <section className="products_card position-relative">
            {/* {isLoading ? <AbsoluteLoader className="rounded-2" /> : ""} */}
            <InfiniteScroll
                dataLength={items?.length || 0}
                next={fetchRecords}
                hasMore={hasMore}
                loader={(
                    <div className="px-0 uza-infinite-scroll">
                        <LoadingContent />
                    </div>
                )}
                endMessage={""}
                className="px-3"
            >

                <Row style={{}}>
                    {items?.length ? (
                        items?.map((item, idx) => {
                            const { minPrice = 0, maxPrice = 0 } = getMinMaxPrices(item);
                            const pendingQty = getPendingQuantity(item);
                            return (
                                <Col lg={3} md={6} sm={12} key={idx}>
                                    <div className="card_comnon-product cursor-pointer h-100" >
                                        <Link target="_blank" to={ROUTES.PRODUCT_DETAIL + "/" + item._id + "?redirectUrl=" + btoa(window.location.href)} className="card_comnon-product cursor-pointer" style={{
                                            textDecoration: "none",
                                            color: "black"
                                        }}    >
                                            <div className="card_item_Show position-relative">
                                                <img
                                                    src={item?.featured_image || placeholder}
                                                    alt=""
                                                    className="img-fluid"
                                                />
                                                {(!item?.manage_stock && item.stock_status === "outofstock" || (item?.manage_stock && item.stock_quantity === 0)) ? <p style={{
                                                    top: "-12px",
                                                    right: "-10px",
                                                }} className="out-of-stock position-absolute">Out of stock</p> : null}
                                            </div>
                                            <div className="card_content_list">
                                                <div className="upper_head text-start w-100">
                                                    <div className="d-flex justify-content-between flex-wrap">
                                                        {fromNow(item.date_created_utc) <= 90 ? (
                                                            <p className="text-danger">
                                                                Listed in last {fromNow(item.date_created_utc)} days
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}


                                                        {pendingQty > 0 && pendingQty <= 10 ? <p className="text-secondary">{pendingQty <= 5 ? `${pendingQty} items left` : pendingQty <= 10 ? "Few left" : ""}</p> : null}
                                                    </div>
                                                    <h5>{item?.name}</h5>
                                                    {/* <p>{item?.short_description?.length >= 250 ? item?.short_description.substring(0, 245) + '...' : item?.short_description}</p> */}
                                                </div>

                                                <div className="products_ist d-flex justify-content-between align-items-center mt-3">
                                                    <p>
                                                        <span>{currentCurrency?.symbol}</span> {amountConversion(minPrice, appConfig)}
                                                        {maxPrice > minPrice && (
                                                            <>
                                                                {" - "} <span>{currentCurrency?.symbol}</span> {amountConversion(maxPrice, appConfig)}
                                                            </>
                                                        )}
                                                    </p>
                                                    {/* <Button className="buy_now_btn" onClick={() => navigate(`${ROUTES.PRODUCT_DETAIL}/${item._id}`)}>Buy Now</Button> */}
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Col>
                            );
                        })
                    ) : isLoading ? "" : (
                        <CommingSoon message={message} />
                    )}
                </Row>
            </InfiniteScroll>
        </section>
    );
};

export default ProductsListingInfinite;
