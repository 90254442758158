import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ROUTES from "../../helpers/routesHelper";
import { apiGetHomeGuaranteedProducts } from "../../store/products/actions";

import placeholder from "../../assets/images/gurone.jpg";
import { Spinner } from "react-bootstrap";

const Gurantedproducts = () => {
  const dispatch = useDispatch();
  const { isLoading, items } = useSelector(
    (s) => s.products.homeGuaranteedProducts
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(apiGetHomeGuaranteedProducts());
  }, [dispatch]);

  // Fallback component for Suspense
  const LoadingFallback = () => (
    <section className="guranted_products text-start my-4">
      <div className="text-start">
        <h4 className="cat_yeloow_head">Guaranted Products</h4>
        <p className="card_sub_head">
          Shipping included prices with guranted delivery
        </p>
      </div>
      <div className="guranted_box d-flex align-items-center justify-content-center mt-3">
        <Spinner />
      </div>
    </section>
  );

  return (
    <>
      {isLoading ? (
        <Suspense fallback={<LoadingFallback />}>
          <LoadingFallback />
        </Suspense>
      ) : items?.length ? (
        <section className="guranted_products text-start my-4">
          <div className="text-start">
            <h4 className="cat_yeloow_head">Guaranted Products</h4>
            <p className="card_sub_head">
              Shipping included prices with guranted delivery
            </p>
          </div>

          <div className="guranted_box d-flex align-items-center gap-3 mt-3">
            {items.map((item, idx) => {
              if (idx < 5)
                return (
                  <div
                    className="box_wrap"
                    key={idx}
                    onClick={() =>
                      navigate(`${ROUTES.PRODUCT_DETAIL}/${item._id}`)
                    }
                  >
                    <img
                      src={item?.featured_image || placeholder}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                );
            })}
          </div>
        </section>
      ) : null}
    </>
  );
};

export default Gurantedproducts;
