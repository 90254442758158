import React, { useState } from "react";
import { Table } from "react-bootstrap";

import AddToCart from "../Common/AddToCart";
import { getActiveAttributes, getItemPrice, getVariationStock, onChangeQuantity } from "../../helpers/cartHelper";
import { amountConversion, isEqualArray, logger } from "../../helpers/commonHelper";
import { useDispatch, useSelector } from "react-redux";
import TempErrorMessage from "../Common/TempErrorMessage";

export default function RenderAddToCartVariation({ detail, index, attribute, onClick = () => { } }) {
    const dispatch = useDispatch();
    const appConfig = useSelector(s => s.config.data);
    const { currentCurrency } = useSelector(s => s.config);
    const [quantityError, setQuantityError] = useState("")
    const [viewMore, setViewMore] = useState(false);


    const toggleViewMore = () => {
        setViewMore(s => !s);
    }


    const getVariationQuantity = (termId) => {
        const activeAttributes = getActiveAttributes(detail?.attributes, termId);

        let quantity = 0;

        let cartQuantity = 0;
        detail?.variations?.forEach((variation) => {
            if (variation?.quantity) {
                cartQuantity += variation.quantity;
            }
            const attributes = variation?.attributes?.map((attr) => attr._id);
            if (isEqualArray(attributes, activeAttributes)) {
                quantity = variation?.quantity || 0;
            }
        });
        return quantity;
    };

    const checkStock = (termId) => {
        logger("VARIATION_STOCK", termId);
        const activeAttributes = getActiveAttributes(detail.attributes, termId);
        let variationIndex = -1;
        detail?.variations?.forEach((variation, id) => {
            const attributes = variation?.attributes?.map((attr) => attr._id);
            if (isEqualArray(attributes, activeAttributes)) {
                variationIndex = id;
            }
        });

        if (variationIndex === -1) {
            return { instock: false };
        }
        return getVariationStock({ attributes: detail.attributes, variations: detail.variations, termId });
    }

    return (
        <div className="add-to-cart-variation-wrapper">
            <div className="divone my-3">
                <TempErrorMessage message={quantityError} temp={false} />
                <p className="mb-3">
                    <strong>
                        {index + 1}. {attribute.name}(
                        {attribute?.terms?.length}):
                    </strong>{" "}
                    {attribute.terms?.map((term, idx) => {
                        if (term?.active) return term.name;
                    })}
                </p>
                <Table className="w-100" responsive>
                    {attribute.terms?.map((term, idx) => {
                        if (!viewMore && idx > 3)
                            return null;

                        return (
                            <tr>
                                <td className="border-bottom">
                                    <div className="d-flex bg-transparent">
                                        {term?.image ? <div className="flex-shrink-0 me-2 cursor-pointer" onClick={() => onClick(term.image)}>
                                            <img src={term?.image} className="img-fluid rounded border  object-fit-cover" style={{ height: 40, width: 40 }} />
                                        </div> : null}
                                        <div className="">
                                            <p className="m-0 " style={{ fontSize: 10, lineHeight: "18px" }}>
                                                {term.name}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                                <td
                                    className="text-center border-bottom"
                                >
                                    <p className="m-0 bg-transparent">
                                        {currentCurrency?.symbol} {amountConversion(getItemPrice(detail), appConfig)}
                                    </p>
                                </td>
                                <td
                                    className="text-end border-bottom"
                                >
                                    <div className="counter_div d-flex align-items-center gap-2 justify-content-end bg-transparent">
                                        {(checkStock(term._id).instock) ?
                                            <>
                                                {/* {checkStock(term._id)?.quantity ? (checkStock(term._id)?.quantity <= 5 ? `${checkStock(term._id)?.quantity} item(s) left` : (checkStock(term._id)?.quantity <= 10 ? 'Few left' : null)) : null} */}
                                                <AddToCart
                                                    value={getVariationQuantity(term._id)}
                                                    min={0}
                                                    onChange={(value) => {
                                                        onChangeQuantity({
                                                            dispatch,
                                                            detail,
                                                            increase: true,
                                                            termId: term._id,
                                                            setQuantity: parseInt(value),
                                                            setError: setQuantityError,
                                                        });
                                                    }}
                                                    onDecrement={() => {
                                                        onChangeQuantity({
                                                            dispatch,
                                                            detail,
                                                            increase: false,
                                                            termId: term._id,
                                                            setError: setQuantityError,
                                                        });
                                                    }}
                                                    onIncrement={() => {
                                                        onChangeQuantity({
                                                            dispatch,
                                                            detail,
                                                            increase: true,
                                                            termId: term._id,
                                                            setError: setQuantityError,
                                                        });
                                                    }}
                                                />
                                            </>
                                            : <p className="out-of-stock">Out of stock</p>
                                        }

                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
            </div>
            {!viewMore && attribute?.terms?.length > 4 ? <div className="d-flex">
                <p className="cursor-pointer fw-semibold text-decoration-underline" onClick={toggleViewMore}>Show More</p>
            </div> : null}
        </div>
    )
}