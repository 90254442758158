import { Spinner } from "react-bootstrap";

export default function BlockContent({ className = "" }) {
  return (
    <>
      <div className={`block-content ${className}`}>
        <Spinner role="status" size={"lg"} animation="grow">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    </>
  );
}
