import moment from "moment";
import DOMPurify from 'dompurify';

export const ENVIRONMENT = process.env.REACT_APP_ENVIORNMENT || "production";

export const logger = (...params) => {
  if (ENVIRONMENT === "development")
    console.log(...params);
};

export const smoothScrollToTop = (query = null) => {
  if (query) {
    document.querySelector(query)?.scrollTo({ top: 0, behavior: "smooth" });
  }
  else {
    document?.body?.scrollTo({ top: 0, behavior: "smooth" });
    window?.scrollTo({ top: 0, behavior: "smooth" });
  }
};

export const handlePageClick = ({ setSkip = () => { }, fetchRecords = () => { } }) => (event) => {
  logger("--> search page --> ", event);
  smoothScrollToTop();
  setSkip(event.selected + 1);
  fetchRecords(event.selected + 1);
};

export const fromNow = (date) => {
  const now = moment();
  const createdAtMoment = moment(date);
  const fromNow = now.diff(createdAtMoment, "days");

  return fromNow - (fromNow % 10) + 10;
};

export const isEqualArray = (arr1, arr2) => {
  arr1.sort();
  arr2.sort();
  return JSON.stringify(arr1) === JSON.stringify(arr2);
};

// Adjusts the number to the specified decimal places, ensuring a non-zero return for small values
export const fixedNumber = (number, toFix = 2, isString = false) => {
  let parsedNumber = parseFloat(number);
  let multiplier = Math.pow(10, toFix);

  // Ensure that very small numbers do not round to zero
  let value = parsedNumber < (1 / multiplier) && parsedNumber > 0
    ? Math.floor(parsedNumber * Math.pow(10, 3)) / Math.pow(10, 3)  // Ensure at least 3 decimal places for very small numbers
    : Math.floor(parsedNumber * multiplier) / multiplier;

  return value;
};

// Uses fixedNumber to format the number, then localize the output
export const formatNumber = (number, toFix = 2, isString = false) => {
  const fixedValue = fixedNumber(number, toFix, isString);

  // If isString is true, the fixedNumber already returns a string, no need for toLocaleString
  if (isString) return fixedValue;

  // Convert the fixed number to a localized string
  return fixedValue.toLocaleString('en-US');
};

export const amountConversion = (amount, config, toFix = 2, isString = false) => {
  let finalAmount = amount;

  // If commission is present in config, adjust the amount accordingly (uncomment if needed)
  // const commission = config?.commission?.per_product || 0;
  // finalAmount = finalAmount + ((commission * finalAmount) / 100);

  // Get the fixed number for the amount, ensuring a non-zero value for very small numbers
  const fixedValue = fixedNumber(finalAmount, toFix, isString);

  // If isString is true, fixedNumber already returns a string, so no need for further formatting
  if (isString) return fixedValue;

  // Convert the fixed amount to a localized string
  return fixedValue.toLocaleString('en-US');
};

export const scrollToId = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const parseText = (text) => {
  const formattedDescription = (text || "").replace(/(?:\\r\n|\\r|\\n)/g, ' ');

  return DOMPurify.sanitize(formattedDescription);
}