import { useEffect, useState } from "react";

export default function TempErrorMessage({ message = "", delay = 3000, className = "", temp = true }) {

    const [show, setShow] = useState(false);
    const handleMessageShow = () => {
        setShow(true);

        if (temp) {
            setTimeout(() => {
                setShow(false);
            }, delay);
        }
    }
    useEffect(() => {
        handleMessageShow();
    }, [message])

    if (show)
        return (
            <>
                <small className={"fst-italic mb-0 text-danger " + className}>{message || ''}</small>
            </>
        );

    return null;
}