import React from "react";
import { Offcanvas, ListGroup, Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, cartDetails, getVariationStock, onChangeQuantity, onChangeVariation } from "../../helpers/cartHelper";

import AddToCart from "../Common/AddToCart";
import { amountConversion, isEqualArray } from "../../helpers/commonHelper";
import RenderAddToCartVariation from "../AddToCartComponents/RenderAddToCartVariation";
import ProductPricing from "../Common/ProductPricing";

export default function CartPropup({ show, setShow, detail, handleLoginShow }) {
  const dispatch = useDispatch();
  const { currentCurrency } = useSelector(s => s.config);
  const appConfig = useSelector(s => s.config.data);
  const { isLogin } = useSelector((s) => s.auth);

  const handleClose = () => setShow(false);

  const cartDetail = cartDetails(detail);

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      style={{ minWidth: "600px" }}
      className="add_offcanva"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Select variations and quantity</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <ListGroup>
          <div className="sku-dialog-content position-relative offcanvas-footer">
            <div className="dialog-layout px-3 py-3">
              <h6>
                <span>Price before shipping</span>
                {detail?.price_tiers?.length && detail?.price_tiers[0]?.startQuantity > 1 ? <p className="min-quantity-label ms-0"><u>{"Min. order: " + detail?.price_tiers[0]?.startQuantity + " pieces"}</u></p> : null}
              </h6>
              <div className="product-price">
                <ProductPricing
                  price_tiers={detail?.price_tiers}
                  defaultPrice={detail?.price}
                />
              </div>

              <hr />

              <div className="sku-layout logistics">
                <ul className="p-0">
                  {detail?.attributes?.map((attribute, index) => {
                    if (
                      index !== detail?.attributes?.length - 1 &&
                      detail?.attributes?.length > 1
                    )
                      return (
                        <li key={index}>
                          <div className="divone my-4">
                            <p className="m-0">
                              <strong>
                                {index + 1}. {attribute.name}(
                                {attribute?.terms?.length}):
                              </strong>{" "}
                              {attribute.terms?.map((term, idx) => {
                                if (term?.active) return term.name;
                              })}
                            </p>

                            <div className="six_color d-flex flex-wrap align-items-center gap-2">
                              {attribute.terms?.map((term, idx) => {
                                return (
                                  <div
                                    className="six_color d-flex align-items-center gap-1"
                                    key={idx}
                                  >
                                    <div
                                      className={
                                        "ram_col d-flex align-items-center justify-content-center cursor-pointer " +
                                        (term?.active ? "active" : "")
                                      }
                                      onClick={() => {
                                        onChangeVariation({
                                          dispatch,
                                          detail,
                                          termIndex: idx,
                                          attributeIndex: index,
                                        });
                                      }}
                                    >
                                      <p className="m-0">{term.name}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </li>
                      );

                    return (
                      <li key={index}>
                        <RenderAddToCartVariation
                          detail={detail}
                          index={index}
                          attribute={attribute}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="subtotal_section px-3">
            <ul className="p-0">
              {detail?.attributes?.length ? (
                <li>
                  <p
                    className="fs-5 cursor-pointer"
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Item subtotal ({cartDetail?.variations} variation{" "}
                    {cartDetail?.quantity} items)
                  </p>
                </li>
              ) : null}
              <li>
                <p className="fs-5">
                  <strong>Total</strong>
                </p>
                <p className="fs-5">
                  <strong>{currentCurrency?.symbol} {amountConversion(detail?.cartPrice || 0, appConfig)}</strong>
                </p>
              </li>
              <li>
                <Button
                  className="Startbutton"
                  disabled={!cartDetail?.isReadyToCart}
                  onClick={() =>
                    isLogin ? addToCart(detail, dispatch) : handleLoginShow()
                  }
                >
                  Add to cart
                </Button>
              </li>
            </ul>
          </div>
        </ListGroup>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
