import React, { useState, useEffect, useRef } from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";

import Homemenustrip from "./Homemenustrip";
import UserAuthCard from "./UserAuthCard";
import ProductSearch from "../Common/ProductSearch";

// images
import Logo from "../../../src/assets/images/logo.png";
import ROUTES from "../../helpers/routesHelper";

const getCategoryList = ({ data, setChooseCategory, chooseCategoryRef }) =>
  data?.map((cat, key) => (
    <React.Fragment key={key}>
      <Button
        className="justify-content-start d-flex align-itmes-center border-light py-2"
        style={{ fontSize: 14 }}
        onClick={() => {
          setChooseCategory({
            catName: cat.catName,
            _id: cat._id,
          });
          if (chooseCategoryRef.current) {
            chooseCategoryRef.current.context.toggle();
          }
        }}
      >
        <p className="m-0 text-truncate">{cat.catName}</p>
      </Button>
      {cat?.subcategories?.length
        ? getCategoryList({
          data: cat.subcategories,
          setChooseCategory,
          chooseCategoryRef,
        })
        : ""}
    </React.Fragment>
  ));

export default function Header({ className = "" }) {
  const dispatch = useDispatch();
  const { isLoading, data, level1, level2, level3 } = useSelector(
    (s) => s.categories.categories
  );
  const [chooseCategory, setChooseCategory] = useState({
    catName: "All Categories",
    _id: "",
  });
  const [initValues, setInitValues] = useState({ search: "" });
  const [nav, setNav] = useState(false);
  const [scroll, setScroll] = useState(false);

  const chooseCategoryRef = useRef();
  const navigate = useNavigate();

  const [search] = useSearchParams();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    setChooseCategory({
      catName: search.get("catName") || "All Categories",
      _id: search.get("category") || "",
    });

    setInitValues({ search: search.get("search") || "" });
  }, [search]);

  return (
    <div style={{ marginTop: 151 }}>
      <section
        style={{ zIndex: 9999999 }}
        className={`${scroll ? "header-main  fixed-header" : "header-main"
          } ${className} py-3 header-section-wrapper`}
      >
        <Container className="p-0">
          <div className="header">
            <nav className="navbar logo-wrapper py-0 navbar-expand-lg flex-shrink-0">
              <div className="container p-0">
                <Link to={ROUTES.HOME} className="navbar-brand">
                  <img src={Logo} alt="logo" className="img-fluid" />
                </Link>
                <button
                  className={`navbar-toggler ${nav ? "" : "menu_click"}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setNav(!nav)}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </button>
                <div
                  className={
                    nav
                      ? "collapse navbar-collapse show"
                      : "collapse navbar-collapse"
                  }
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav scroll-menu ms-auto mb-2 mb-lg-0">
                    {/* <Button
                      onClick={() => setNav(false)}
                      className="close-menu"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--gg"
                        width="28"
                        height="28"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#ffffff"
                          d="M6.225 4.811a1 1 0 0 0-1.414 1.414L10.586 12L4.81 17.775a1 1 0 1 0 1.414 1.414L12 13.414l5.775 5.775a1 1 0 0 0 1.414-1.414L13.414 12l5.775-5.775a1 1 0 0 0-1.414-1.414L12 10.586L6.225 4.81Z"
                        />
                      </svg>
                    </Button> */}
                  </ul>
                </div>
              </div>
            </nav>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="search_card_column w-100 ">
                <Formik
                  initialValues={initValues}
                  onSubmit={(data) => {
                    search.set("search", data.search);
                    search.set("catName", chooseCategory.catName);
                    search.set("category", chooseCategory._id);
                    search.set("skip", 1);
                    navigate(ROUTES.PRODUCT_LISTING + "?" + search.toString());
                  }}
                  enableReinitialize={true}
                >
                  {({ setFieldValue, values }) =>
                    <Form action={ROUTES.PRODUCT_LISTING}>
                      <div className="search_with_dropdonw">
                        <div className="dropdown_all_cat">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="d-flex align-items-center"
                              ref={chooseCategoryRef}
                            >
                              <span
                                className="m-0 d-block"
                                style={{
                                  maxWidth: 100,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {chooseCategory.catName}
                              </span>
                              <span className="ms-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="28"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7t.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275t.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"
                                  />
                                </svg>
                              </span>
                            </DropdownToggle>

                            <DropdownMenu
                              className="category-list-menu"
                              style={{ zIndex: 9999 }}
                            >
                              <Button
                                className="justify-content-start d-flex align-itmes-center"
                                style={{ fontSize: 14 }}
                                onClick={() => {
                                  setChooseCategory({
                                    catName: "All Categories",
                                    _id: "",
                                  });
                                  if (chooseCategoryRef.current) {
                                    chooseCategoryRef.current.context.toggle();
                                  }
                                }}
                              >
                                All Categories
                              </Button>
                              {[...level1, ...level2, ...level3]?.length
                                ? getCategoryList({
                                  data: [...level1, ...level2, ...level3],
                                  setChooseCategory,
                                  chooseCategoryRef,
                                })
                                : ""}
                            </DropdownMenu>
                          </UncontrolledDropdown>

                          <div className="dividerline_search"></div>

                          <div className="search_bar d-flex align-items-center">
                            <ProductSearch callback={({ search, category }) => {
                              setFieldValue("search", search);
                              setChooseCategory(state => ({ ...state, _id: category }));
                            }}
                              category={chooseCategory?._id || ""}
                              defaultValue={values.search}
                            />
                            {/* <Field
                            type="text"
                            id="search"
                            name="search"
                            placeholder="Search for Products Brands and more..."
                          /> */}
                            <input
                              type="hidden"
                              name="category"
                              value={chooseCategory._id}
                            />
                            <input
                              type="hidden"
                              name="catName"
                              value={chooseCategory.catName}
                            />
                          </div>
                        </div>
                        <button type="submit" className="search_button">
                          {searchicon}
                        </button>
                      </div>
                    </Form>
                  }
                </Formik>
              </div>
              <UserAuthCard />
            </div>
          </div>
        </Container>
        {/* <div className="search_card_column">
          <Form action={ROUTES.PRODUCT_LISTING}>
            <div className="search_with_dropdonw">
              <div className="dropdown_all_cat">
                <UncontrolledDropdown>
                  <DropdownToggle caret ref={chooseCategoryRef}>
                    {chooseCategory.catName}
                  </DropdownToggle>

                  <DropdownMenu className="category-list-menu">
                    <Button
                      onClick={() => {
                        setChooseCategory({
                          catName: "All Categories",
                          _id: "",
                        });
                        if (chooseCategoryRef.current) {
                          chooseCategoryRef.current.context.toggle();
                        }
                      }}
                    >
                      All Categories
                    </Button>
                    {data?.length
                      ? getCategoryList({
                        data,
                        setChooseCategory,
                        chooseCategoryRef,
                      })
                      : ""}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <div className="dividerline_search"></div>

                <div className="search_bar d-flex align-items-center">
                  <input
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search for Products Brands and more..."
                    defaultValue={search.get("search") || ""}
                  />
                  <input
                    type="hidden"
                    name="category"
                    value={chooseCategory._id}
                  />
                  <input
                    type="hidden"
                    name="catName"
                    value={chooseCategory.catName}
                  />
                </div>
              </div>
              <button type="submit" className="search_button">
                {searchicon}
              </button>
            </div>
          </Form>
        </div> */}
      </section>

      <div className={`${scroll ? "menu_strip  w-100 fixed-strip" : "menu_strip"
        } ${className} header-section-wrapper w-100`} style={{ top: 105 }}>
        <Homemenustrip scroll={scroll} />

        {/* {!pathname.includes("/all-categories") && <Homemenustrip />} */}
        {/* {pathname.includes("/all-categories") && <Categoriesstrip />} */}
      </div>
    </div>
  );
}

const searchicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M15.096 5.90399C14.4945 5.29131 13.7775 4.80387 12.9865 4.46983C12.1956 4.13579 11.3462 3.96177 10.4876 3.95783C9.62902 3.95388 8.77813 4.12008 7.98412 4.44684C7.19011 4.7736 6.4687 5.25443 5.86157 5.86156C5.25443 6.4687 4.7736 7.19011 4.44684 7.98412C4.12009 8.77813 3.95388 9.62901 3.95783 10.4876C3.96177 11.3462 4.13579 12.1956 4.46983 12.9865C4.80387 13.7775 5.29131 14.4945 5.904 15.096C7.12675 16.2965 8.77409 16.9656 10.4876 16.9577C12.2012 16.9498 13.8423 16.2656 15.054 15.054C16.2656 13.8423 16.9498 12.2012 16.9577 10.4876C16.9656 8.77408 16.2965 7.12675 15.096 5.90399ZM4.49 4.48999C6.02282 2.95756 8.08315 2.06857 10.2497 2.00482C12.4162 1.94108 14.5252 2.7074 16.1455 4.14706C17.7658 5.58673 18.7749 7.59099 18.9664 9.74997C19.1579 11.909 18.5175 14.0596 17.176 15.762L22.521 21.107L21.107 22.521L15.762 17.176C14.059 18.5125 11.9102 19.149 9.75406 18.9556C7.59787 18.7622 5.5967 17.7534 4.15878 16.1351C2.72085 14.5168 1.95449 12.4109 2.01604 10.2469C2.07759 8.08292 2.96241 6.02395 4.49 4.48999Z"
      fill="white
    "

    ></path>
  </svg>
);
