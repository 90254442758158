import React from "react";
import { Container } from "react-bootstrap";
import { Button, FormGroup, Input } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import ROUTES from "../../helpers/routesHelper";
import ProductSearch from "../../Components/Common/ProductSearch";
import { useState } from "react";
import { useEffect } from "react";
import apiClient from "../../helpers/apiHelper";
import { PRODUCTS } from "../../helpers/urlHelper";

const Homebanner = () => {

  const [frequentlySearch, setFrequentlySearch] = useState([]);

  const [search] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {

    (async () => {
      try {
        const res = await apiClient.get(PRODUCTS.FREQUENTLY_SEARCH);
        setFrequentlySearch(res?.data || []);
      }
      catch (error) { }
    })();
  }, []);
  return (
    <section className="home_banner position-relative">
      <div className="banner_overlay"></div>
      <Container>
        <div className="inner_bnaner_content text-start">
          <h1>Learn uzabulk.com</h1>
          <p>The leading B2B ecommerce platform for global trade</p>

          <Formik initialValues={{ search: "", category: "" }} onSubmit={(data) => {
            search.set("search", data.search);
            navigate(ROUTES.PRODUCT_LISTING + "?" + search.toString());
          }}>
            {({ setFieldValue }) => (
              <Form action={ROUTES.PRODUCT_LISTING}>
                <FormGroup className="position-relative">
                  <Field name="search" className={"form-control"} />
                  {/* <ProductSearch
                    callback={({ search, category }) => {
                      setFieldValue("search", search);
                      setFieldValue("category", category);
                    }}
                    placeholder=""
                  /> */}
                  {/* <Field className="form-control" type="search" name="search" id="searchid" placeholder="" /> */}
                  <Button type="submit" className="banner_search">Search</Button>
                </FormGroup>
              </Form>
            )}
          </Formik>

          {frequentlySearch?.length ? (
            <div className="frequently_search align-items-center gap-3 mt-3 mt-lg-5">
              <h6>Frequently Searched: </h6>
              <ul>{frequentlySearch?.map((value, key) => (
                <li key={key}>
                  <Link to={`${ROUTES.PRODUCT_LISTING}?search=${value.search}`} className="text-decoration-none text-white">
                    {value.search}
                  </Link>
                </li>
              ))}
              </ul>
            </div>
          ) : null}

        </div>
      </Container>
    </section>
  );
};

export default Homebanner;
