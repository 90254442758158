import { useSelector } from "react-redux";
import { amountConversion, formatNumber } from "../../helpers/commonHelper";

export default function ProductPricing({ defaultPrice, price_tiers }) {
    const { currentCurrency } = useSelector(s => s.config);
    const appConfig = useSelector(s => s.config.data);
    return (
        <ul className="p-0 d-flex align-items-center gap-4 flex-wrap">
            {price_tiers?.length ? (
                price_tiers?.map((price, index) => {
                    return (
                        <li key={index}>
                            <div className="same_content_single">
                                <p>
                                    {price_tiers?.length - 1 === index
                                        ? `>=${formatNumber(price.startQuantity)} pieces`
                                        : `${formatNumber(price.startQuantity)}-${formatNumber(price_tiers[index + 1].startQuantity - 1)} pieces`
                                    }
                                </p>
                                <h3>{currentCurrency?.symbol} {amountConversion(price.price, appConfig)}</h3>
                            </div>
                        </li>
                    );
                })
            ) : (
                <li>
                    <div className="same_content_single">
                        <h3>{currentCurrency?.symbol} {defaultPrice}</h3>
                    </div>
                </li>
            )}
        </ul>
    );
}