import React from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import ROUTES from '../helpers/routesHelper';

const BaseTheme = ({ header = true, footer = true }) => {

    const location = useLocation();
    const hideHeaderFooterRoutes = [
        ROUTES.LOGIN,
        ROUTES.SIGNUP,
        ROUTES.FORGOT,
    ];

    const hideHeaderFooter = hideHeaderFooterRoutes.includes(location.pathname);

    return (
        <div>
            {!hideHeaderFooter && <Header />}
            <Outlet />
            {!hideHeaderFooter && <Footer />}
        </div>
    );
};

export default BaseTheme;
