import { useDispatch } from "react-redux";
import { cartDetails, onChangeVariation } from "../../helpers/cartHelper";
import { formatNumber, logger } from "../../helpers/commonHelper";
import RenderAddToCartVariation from "../../Components/AddToCartComponents/RenderAddToCartVariation";

export function ProductVariations({ detail, setShow, handlerAddToCart, onClick }) {
  const dispatch = useDispatch();

  logger('attributes', detail.attributes);
  logger('variations', detail.variations);

  const cartDetail = cartDetails(detail);

  return (
    <>
      <div className="variants">
        <div className="variants_title">
          <h6>Variations</h6>
        </div>

        <div className="total_variation d-flex align-items-center gap-2">
          <p className="w-75 text-truncate">
            Total options:{" "}
            {detail?.attributes?.map(
              (attribute) => `${formatNumber(attribute?.terms?.length)} ${attribute.name}; `
            )}
          </p>
          {!cartDetail.directVariationCart ?
            <div className="select_now">
              <div
                className="cursor-pointer"
                onClick={handlerAddToCart}
                style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
              >
                Select Now
              </div>
            </div>
            : null}

        </div>

        {detail?.attributes?.length ? (
          <ul className="p-0">
            {detail?.attributes?.length === 1 ? <RenderAddToCartVariation
              detail={detail}
              index={0}
              attribute={detail?.attributes[0]}
              onClick={onClick}
            />
              :
              detail?.attributes?.map((attribute, index) => {
                return (
                  <li key={index}>
                    <div className="divone my-3">
                      <p className="m-0">
                        <strong>
                          {index + 1}. {attribute.name}({formatNumber(attribute?.terms?.length)}):
                        </strong>{" "}
                        {attribute.terms?.map((term, idx) => {
                          if (term?.active) return term.name;
                          return null;
                        })}
                      </p>

                      <div className="six_color d-flex align-items-center gap-2 flex-wrap">
                        {attribute.terms?.map((term, idx) => {
                          return (
                            <div
                              className="six_color d-flex align-items-center gap-1 cursor-pointer"
                              key={idx}
                            >
                              <div
                                className={
                                  "ram_col d-flex align-items-center justify-content-center " +
                                  (term?.active ? "active" : "")
                                }
                                onClick={() => {
                                  if (index === detail?.attributes?.length - 1) {
                                    setShow(true);
                                  } else {
                                    onChangeVariation({
                                      dispatch,
                                      detail,
                                      termIndex: idx,
                                      attributeIndex: index,
                                    });
                                  }
                                }}
                              >
                                <p className="m-0">{term.name}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        ) : null}

        {/* <ul className="p-0">
          <li>
            <div className="divone my-3">
              <p className="m-0">
                <strong>1. Color(6):</strong> Gold
              </p>

              <div className="six_color d-flex align-items-center gap-1">
                {[1, 2, 3, 4, 5, 6].map((item, idx) => {
                  return (
                    <div className="colors_col" key={idx}>
                      <img src={Sousix} alt="" className="img-fluid" />
                    </div>
                  );
                })}
              </div>
            </div>
          </li>

          <li>
            <div className="divone my-3">
              <p className="m-0">
                <strong>2. RAM(1):</strong> 16g
              </p>

              <div className="six_color d-flex align-items-center gap-1">
                <div className="ram_col d-flex align-items-center justify-content-center">
                  <p className="m-0">16g</p>
                </div>
              </div>
            </div>
          </li>

          <li>
            <div className="divone my-3">
              <p className="m-0">
                <strong>3. Storage Capacity(1):</strong>
              </p>

              <div className="six_color d-flex align-items-center gap-1">
                <div className="ram_col d-flex align-items-center justify-content-center">
                  <p className="m-0">1TB</p>
                </div>
              </div>
            </div>
          </li>
        </ul> */}
      </div>

    </>
  );
}
