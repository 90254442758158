import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ROUTES from '../helpers/routesHelper';

const PrivateRoutesTheme = ({ isLogin }) => {

    const navigate = useNavigate();
    useEffect(() => {
        if (!isLogin) {
            navigate(ROUTES.LOGIN);
        }
    }, [isLogin]);

    if (isLogin)
        return <Outlet />;

    return null;
};

export default PrivateRoutesTheme;
