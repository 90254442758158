import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ROUTES from "../../helpers/routesHelper";

const MerchantSignupSuccess = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.data) {
      navigate(ROUTES.HOME);
    }
  }, [state]);

  return (
    <section className="congrats_page">
      <Helmet>
        <title>Merchant form subbmited</title>
      </Helmet>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="congrats_img">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="250"
                height="250"
                x="0"
                y="0"
                viewBox="0 0 512 512"
                style={{ enableBackground: 'new 0 0 512 512' }}
                xmlSpace="preserve"
              >
                <g>
                  <path
                    fill="#233448"
                    d="m484.773 298.404-.149.074c-17.55 13.459-24.986 36.362-18.739 57.556l.074.149c9.964 33.686-14.723 67.668-49.823 68.561h-.148c-22.16.595-41.643 14.723-49.005 35.619v.074c-11.75 33.165-51.755 46.178-80.682 26.174-17.962-12.265-41.707-12.905-60.605 0h-.074c-28.926 19.929-68.933 6.99-80.608-26.249a53.44 53.44 0 0 0-49.004-35.619h-.149c-35.098-.893-59.787-34.875-49.822-68.561l.074-.149c6.245-21.194-1.191-44.097-18.739-57.556l-.149-.074c-27.886-21.417-27.886-63.356 0-84.772l.149-.074c17.548-13.459 24.984-36.363 18.665-57.556v-.149c-10.04-33.685 14.723-67.669 49.822-68.561h.149c22.085-.595 41.642-14.724 49.004-35.619v-.074c11.674-33.165 51.682-46.178 80.608-26.174h.074c18.218 12.567 42.311 12.567 60.605 0 29.218-20.177 69.001-6.792 80.682 26.174v.074c7.362 20.821 26.844 35.025 49.005 35.619h.148c35.099.892 59.787 34.876 49.823 68.561l-.074.149c-6.247 21.193 1.189 44.097 18.739 57.556l.149.074c27.886 21.416 27.886 63.356 0 84.773z"
                    opacity="1"
                    data-original="#3eb655"
                  ></path>
                  <circle
                    cx="256"
                    cy="256"
                    r="138.517"
                    fill="#f3b04c"
                    opacity="1"
                    data-original="#8bd399"
                  ></circle>
                  <path
                    d="M362.355 167.333c-23.959-19.71-54.612-31.557-88.028-31.557-76.5 0-138.55 62.05-138.55 138.55 0 33.416 11.847 64.069 31.556 88.028-30.441-25.393-49.831-63.59-49.831-106.356 0-76.501 61.997-138.497 138.497-138.497 42.766 0 80.963 19.39 106.356 49.832z"
                    opacity="1"
                    fill="#00000010"
                    data-original="#00000010"
                  ></path>
                  <path
                    fill="#ffffff"
                    d="m223.045 310.226-30.631-32.588c-8.022-8.536-7.608-21.957.925-29.979 8.533-8.032 21.96-7.601 29.975.929l14.622 15.55 62.153-71.038c7.704-8.816 21.104-9.71 29.927-1.995 8.816 7.715 9.706 21.111 1.995 29.927l-77.555 88.635c-8.262 9.433-22.843 9.68-31.411.559z"
                    opacity="1"
                    data-original="#ffffff"
                  ></path>
                </g>
              </svg>
            </div>
          </Col>

          <Col lg={6} md={6} sm={12}>
            <center>
              <div className="consgratulation_Content">
                <h4>Registration Successful!</h4>

                <p className="fs-6">
                  Thank you for registering as a vendor. Your account is now under review, and we will notify you once it is approved. You can start adding your products soon!
                </p>

                <Link to={ROUTES.HOME} className="mt-3">Go to Home</Link>
              </div>
            </center>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MerchantSignupSuccess;
